@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/*-----------Animations Variables-------------*/
@import './assets/icons/style.css';
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent !important;
}

@media only screen and (max-width: 480px) {
  ::-webkit-scrollbar-track {
    background: #00000040;
  }
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #00000040 !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--ion-color-secondary);
}

@media only screen and (max-width: 480px) {
  ::-webkit-scrollbar-thumb {
    background: #00000040;
  }
}
/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
.d-flex {
  display: flex;
  transition: all 0.1s linear;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-one {
  flex: 1;
}

/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
.app-button {
  position: relative;
  border: none;
  background: var(--ion-color-primary);
  cursor: pointer;
  font-size: 18px;
  padding: 8px;
  color: white;
}

.app-button:focus {
  outline: none;
}

.app-button:hover {
  background: var(--ion-color-tertiary);
  color: white;
}

.app-button.primary {
  background: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
  border: none;
}

.app-button.primary:hover {
  background: white;
  color: var(--ion-color-tertiary);
}

.app-button.primary:hover i, .app-button.primary:hover span {
  color: var(--ion-color-tertiary);
}

.app-button.gray {
  background: #F2F2F2;
  color: var(--ion-color-dark);
  border: 1px solid #F2F2F2;
}

.app-button.gray:hover {
  background: #FFFFFF;
  color: var(--ion-color-primary);
  border: 1px solid var(--ion-color-primary);
}

.app-button.light {
  background: rgba(var(--ion-color-secondary-rgb), 1);
  color: white;
}

.app-button.light:hover {
  filter: brightness(120%);
}

.app-button.transparent {
  background: transparent;
  color: var(--ion-color-tertiary);
}

.app-button.transparent:hover {
  border-color: var(--ion-color-tertiary) !important;
}

.app-button:disabled, .app-button[disabled], .app-button.disabled {
  background: #1A2538 !important;
  color: #485875 !important;
  border: 1px solid #485875 !important;
}

.app-button:disabled:hover, .app-button[disabled]:hover, .app-button.disabled:hover {
  background: #1A2538 !important;
  color: #485875 !important;
  border: 1px solid #485875 !important;
}

.app-button.lg-padding {
  padding: 8px 28px;
}

.app-button.width-100 {
  width: 100%;
}

.app-link {
  text-decoration: none;
}

.transparent-with-outline-style {
  z-index: 1;
  /* This controls the background of the button before hover */
}

.transparent-with-outline-style:hover {
  color: #FFFFFF;
}

.transparent-with-outline-style:hover:before {
  background: linear-gradient(to left, #6501FF, #0177FF);
}

.transparent-with-outline-style:disabled:after, .transparent-with-outline-style[disabled]:after, .transparent-with-outline-style.disabled:after {
  background: #485875;
}

.transparent-with-outline-style:disabled.reversed:after, .transparent-with-outline-style[disabled].reversed:after, .transparent-with-outline-style.disabled.reversed:after {
  background: #485875;
}

.transparent-with-outline-style:disabled:hover, .transparent-with-outline-style[disabled]:hover, .transparent-with-outline-style.disabled:hover {
  color: #FFFFFF;
}

.transparent-with-outline-style:disabled:hover:before, .transparent-with-outline-style[disabled]:hover:before, .transparent-with-outline-style.disabled:hover:before {
  background: var(--ion-color-primary) !important;
}

.transparent-with-outline-style:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--ion-color-primary);
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #6501FF, #0177FF);
  /* adding this because the buttons are so ugly! */
}

.transparent-with-outline-style:after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  background: linear-gradient(to right, #D71FFF, #3BD8FF);
  z-index: -2;
  top: -2px;
  left: -2px;
}

.transparent-with-outline-style.reversed:after {
  background: linear-gradient(to right, #3BD8FF, #D71FFF);
}

.empower-button-container {
  z-index: 2;
}

.empower {
  flex-grow: 1;
  margin-right: 12px;
}

button {
  padding: 12px 20px;
}

button:hover {
  color: white;
}

.empower-button-style {
  background: linear-gradient(90deg, #6501FF, #0177FF);
  width: 100%;
}

.empower-button-style:hover {
  background: linear-gradient(270deg, #6501FF, #0177FF);
  color: #FFFFFF;
}

.login-button {
  background: rgba(247, 195, 60, 0.15);
  border: 2px solid var(--ion-color-tertiary);
  margin-left: auto;
  margin-right: 8px;
}

.login-button:hover {
  color: #FFFFFF;
  border: 2px solid var(--ion-color-tertiary);
  background: var(--ion-color-tertiary);
}

.app-coin {
  margin: 0 6px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.app-coin.w-h-50 {
  width: 50px;
  height: 50px;
}

/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
.modal-background .mat-dialog-container {
  background: #12161c;
}

.mat-button-wrapper {
  color: #485875;
}

@media screen and (max-width: 576px) {
  .small-screen .mat-dialog-container {
    padding: 6px !important;
    min-height: 400px;
    overflow-y: auto;
  }
}
.mat-divider {
  border-top-color: #485575;
}

.mat-tab-labels {
  background-color: #1b273b;
  justify-content: center;
  width: 80%;
  margin: auto;
  margin-top: 1em;
}

.mat-tab-label {
  opacity: 1;
  flex-grow: 1;
}

.mat-tab-label-content {
  color: white;
}

.buy.mat-tab-label-active {
  background-color: var(--ion-color-tertiary);
}

.sell.mat-tab-label-active {
  background-color: var(--ion-color-danger);
}

.buy-sell-nav.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}

.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--ion-color-tertiary);
}

.mat-tab-list {
  margin-bottom: 1em;
}

.mat-tab-links {
  margin-top: 1em;
  background-color: var(--ion-color-secondary);
}

.mat-tab-link {
  flex-grow: 1;
  opacity: 1 !important;
  color: white;
}

.options-nav .mat-tab-links {
  background-color: transparent;
}

.order-form-inputs .mat-form-field-label,
.order-form-inputs ::placeholder {
  color: var(--ion-color-input-placeholder);
}

.mat-form-field-underline {
  background-color: red;
}

.order-button .mat-button-wrapper {
  color: white;
}

.mat-form-field-underline {
  background-color: white !important;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: var(--ion-color-tertiary);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--ion-color-tertiary);
}

.margin-auto {
  margin: auto;
}

.pointer-events-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
.icon {
  color: var(--ion-color-medium);
  cursor: pointer;
}

.icon:focus {
  outline: none;
}

.icon:hover {
  color: var(--ion-color-tertiary);
}

@media only screen and (max-width: 480px) {
  .icon:hover {
    color: #485875;
  }
}
.icon-theme {
  color: var(--text-color);
}

.icon-white {
  color: white;
}

.icon-primary {
  color: var(--ion-color-primary);
}

.icon-light-grey {
  color: #485875;
}

.icon-bold {
  font-weight: bold;
}

/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
/*--- Classes ------------*/
/*------- Colors ------*/
.success {
  color: var(--ion-color-success);
}

.success-dark {
  color: var(--ion-color-success-shade);
}

.warning {
  color: var(--ion-color-danger) !important;
}

.tertiary {
  color: var(--ion-color-tertiary);
}

.buy {
  color: var(--ion-color-buy);
}

.warning-yellow {
  color: var(--ion-color-warning);
}

.warning-red {
  color: var(--ion-color-warningred);
}

.primary {
  color: var(--ion-color-primary);
}

.dark {
  color: var(--ion-color-dark);
}

.medium {
  color: var(--ion-color-medium);
}

.light-grey {
  color: #52617c54;
}

.medium-grey {
  color: var(--ion-color-medium-grey);
}

.icon-star-filled {
  color: #3a414d;
}

.bright-light-grey {
  color: #7887A2;
}

.tint-grey {
  color: var(--ion-color-medium-tint);
}

.light {
  color: var(--ion-color-light);
}

.text-color-secondary {
  color: var(--ion-color-secondary);
}

.text-color-light-mode {
  color: var(--ion-text-color);
}

.neutral {
  color: var(--ion-color-medium);
}

.grayscale {
  filter: grayscale(1);
}

.modal-background {
  background-color: #12161c;
}

.generic-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin: 0 6px;
  background: rgba(var(--ion-color-tertiary-rgb), 0.2);
}

.gradient-outline-border {
  position: relative;
  z-index: 1;
}

.gradient-outline-border:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--ion-color-primary);
  z-index: -1;
}

.gradient-outline-border:after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  transform: translateX(-2px);
  background: linear-gradient(to right, #6501FF, #0177FF);
  z-index: -2;
}

.gradient-outline-border-hover {
  position: relative;
  z-index: 1;
}

.gradient-outline-border-hover:hover:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--ion-color-primary);
  z-index: -1;
}

.gradient-outline-border-hover:hover:after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  transform: translateX(-2px);
  background: linear-gradient(to right, #6501FF, #0177FF);
  z-index: -2;
}

.active-card-border {
  transition: all 0.1s ease;
  border-right: 4px solid var(--ion-color-primary);
  border-left: 4px solid var(--ion-color-primary);
  background: var(--ion-color-primary);
}

.active-card-border:hover {
  background: rgba(247, 195, 60, 0.15);
  border-right-color: #f7c33c;
}

.active-card-border.active {
  background: rgba(247, 195, 60, 0.15);
  border-right-color: #f7c33c;
}

.font-size-sm {
  font-size: 12px;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-m {
  font-size: 24px;
}

.font-size-lg {
  font-size: 36px;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.main-title {
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
}

@media only screen and (min-width: 780px) {
  .icon-close {
    position: absolute;
    top: 18px;
    right: 22px;
    cursor: pointer;
    padding: 10px;
    margin: -20px;
    transition: color 100ms ease;
  }
}
.icon-close:hover {
  color: rgba(255, 255, 255, 0.7);
}

/*-----------Animations Variables-------------*/
.fade-in-1 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0s;
  animation-fill-mode: forwards;
}

.fade-in-2 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.15s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.15s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.15s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.15s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.15s;
  animation-fill-mode: forwards;
}

.fade-in-3 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.3s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.3s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.3s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.3s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in-4 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.45s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.45s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.45s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.45s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.45s;
  animation-fill-mode: forwards;
}

.fade-in-5 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.6s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.6s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.6s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.6s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.6s;
  animation-fill-mode: forwards;
}

.fade-in-6 {
  opacity: 0;
  animation-timing-function: ease-in;
  animation: fadeIn 1s;
  animation-delay: 0.75s;
  -webkit-animation: fadeIn 1s;
  -webkit-animation-delay: 0.75s;
  -moz-animation: fadeIn 1s;
  -moz-animation-delay: 0.75s;
  -o-animation: fadeIn 1s;
  -o-animation-delay: 0.75s;
  -ms-animation: fadeIn 1s;
  -ms-animation-delay: 0.75s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.external-link {
  display: inline-block;
  height: 24px;
  width: 24px;
}

.external-link::after {
  content: url("assets/images/hyperlink.png");
  transform: scale(0.11);
  width: 8px;
  height: 0px;
  display: inline-block;
}

.side-thing {
  background-color: #12161c;
  vertical-align: top;
  width: 20vw;
  height: 100%;
  display: inline-block;
}

.left-side {
  border-right: 6px solid #1e232c;
}

.right-side {
  border-left: 6px solid #1e232c;
}

.middle-thing {
  background-color: #12161c;
  height: 100%;
  padding: 20px;
}

.message {
  font-size: 22px;
}

#onboarding-arrow img {
  max-height: 140px;
}

#message-please-login {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding-top: 10%;
}

#wallet-arrow {
  display: none;
}

#onboarding-arrow {
  display: none;
  width: 15%;
  text-align: right;
  padding: 0px 2% 0 0;
}

@media only screen and (max-width: 480px) {
  #onboarding-arrow {
    display: inline-block;
  }

  #message-please-login {
    width: 85%;
  }

  #message-connect-wallet {
    padding-top: 15vh;
    display: block;
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 81%;
    padding-top: 15vh;
    min-height: 40vh;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }

  #wallet-arrow {
    display: block;
    text-align: right;
    width: 87%;
    padding-top: 14vh;
    position: fixed;
    bottom: 70px;
    right: 30%;
  }

  #wallet-arrow img {
    transform: scaleY(-1) scaleX(1);
  }
}
.onboarding-animation {
  pointer-events: none;
}

.angled-scale {
  -webkit-transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  transform: scale3d(1, 1, 2) rotate3d(1, 0, 0, 60deg);
  pointer-events: none;
}

.pulse-animation {
  pointer-events: none;
  width: 35px;
  height: 35px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 50%;
  background: rgba(255, 196, 9, 0.2);
  box-shadow: 0 0 0 0 #ffc409;
  animation: pulse 1.5s infinite;
  -moz-animation: pulse 1.5s infinite;
  -webkit-animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-moz-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
@-webkit-keyframes pulse {
  0% {
    transform: translate(0, 0px) scale(1);
  }
  70% {
    box-shadow: 0 0 0 50px rgba(255, 196, 9, 0);
    background: rgba(255, 196, 9, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 196, 9, 0);
  }
}
.bounce {
  animation: bounce 1.5s infinite;
  -moz-animation: bounce 1.5s infinite;
  -webkit-animation: bounce 1.5s infinite;
}

@keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-moz-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@-webkit-keyframes bounce {
  0% {
    transform: translate(0, 0px);
  }
  70% {
    transform: translateY(15px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@media only screen and (max-width: 480px) {
  .side-thing {
    display: none;
  }
}
/*-----------New Variables-------------*/
/*----------Font Sizes---------------*/
/*-----------Colors-------------*/
/*--------------break points-------------------*/
/*------------Animations---------------*/
.shake {
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  animation-name: shakeMe;
  animation-duration: 0.2s;
  animation-iteration-count: 5;
  animation-timing-function: linear;
}

@keyframes shakeMe {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
/* Mixins */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input:focus-visible {
  outline: none;
}

input {
  background: transparent;
}

.generic-input {
  border: 1px solid #485875;
  padding: 6px;
  color: #FFFFFF;
  min-height: 35px;
}

.generic-input:focus::placeholder {
  opacity: 0;
}

.generic-input::placeholder {
  color: #485875;
  font-size: 14px;
}

.generic-input.dark {
  background: var(--ion-color-primary-shade);
}

.generic-input.error {
  border: 2px solid var(--ion-color-danger);
}

.gradient-border {
  --borderWidth: 2px;
  position: relative;
  z-index: 1;
}

.gradient-border:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #D71FFF, #3BD8FF, #D71FFF, #3BD8FF);
  -webkit-animation: animatedgradient 5s linear infinite;
  animation: animatedgradient 5s linear infinite;
  background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
body {
  overflow-x: hidden;
}

.block {
  position: absolute;
  left: 0;
  top: 0;
}

.card {
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.sliderContainer {
  position: relative;
  text-align: center;
  line-height: 40px;
  background: #f7f9fa;
  color: #45494c;
  border-radius: 2px;
  max-width: 276px;
}

.sliderbg {
  background-color: #f7f9fa;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #e6e8eb;
}

.sliderContainer_active .slider {
  top: -1px;
  border: 1px solid #1991FA;
}

.sliderContainer_active .sliderMask {
  border-width: 1px 0 1px 1px;
}

.sliderContainer_success .slider {
  top: -1px;
  border: 1px solid #52CCBA;
  background-color: #52CCBA !important;
}

.sliderContainer_success .sliderMask {
  border: 1px solid #52CCBA;
  border-width: 1px 0 1px 1px;
  background-color: #D2F4EF;
}

.sliderContainer_success .sliderIcon:before {
  content: "";
}

.sliderContainer_fail .slider {
  top: -1px;
  border: 1px solid #f57a7a;
  background-color: #f57a7a !important;
}

.sliderContainer_fail .sliderMask {
  border: 1px solid #f57a7a;
  background-color: #fce1e1;
  border-width: 1px 0 1px 1px;
}

.sliderContainer_fail .sliderIcon:before {
  content: "";
}

.sliderContainer_active .sliderText, .sliderContainer_success .sliderText, .sliderContainer_fail .sliderText {
  display: none;
}

.sliderMask {
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  border: 0 solid #1991FA;
  background: #D1E9FE;
  border-radius: 2px;
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.2s linear;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider:hover {
  background: #1991FA;
}

.slider:hover .sliderIcon {
  background-position: 0 -13px;
}

.sliderText {
  position: absolute;
  top: 0;
  left: 69px;
}

.sliderIcon {
  font-size: 25px;
}

.refreshIcon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  margin: 6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  z-index: 5;
  transition: color 0.3s linear;
}

.refreshIcon:hover {
  color: #6c757d;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
@font-face {
  font-family: "icomoon";
  src: url("./assets/icons/fonts/icomoon.woff") format("woff"), url("./assets/icons/fonts/icomoon.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  scrollbar-color: #00000070 !important;
  scrollbar-width: thin !important;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
}

html,
body {
  height: 100vh;
  font-size: 14px;
}

body {
  color: var(--ion-text-color);
}

input {
  -webkit-user-select: text;
  -moz-user-select: unset;
  caret-color: var(--ion-color-tertiary);
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 100rem transparent inset !important;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0 100rem rgba(0, 0, 0, 0);
  transition: background-color 5000s ease-in-out 0s;
}

mat-carousel {
  width: 100%;
  height: 100%;
}

mat-carousel .carousel {
  height: 100% !important;
}

mat-carousel .carousel ul,
mat-carousel .carousel ul li {
  height: 100% !important;
}

mat-carousel .carousel .carousel-indicators {
  bottom: 0px !important;
}

mat-carousel .carousel .carousel-indicators button {
  background-color: rgba(255, 255, 255, 0.493) !important;
}

mat-carousel .carousel .carousel-indicators button.mat-button-disabled {
  background-color: white !important;
}

.no-x-scroll .mat-dialog-container {
  overflow-x: hidden;
}

.to-address span {
  user-select: all;
}

ngx-skeleton-loader .loader {
  background-color: var(--ion-color-secondary-shade) !important;
}

iframe {
  z-index: 997 !important;
  right: 10px !important;
  border-radius: 50px !important;
  padding: 3px !important;
}

@media screen and (max-width: 992px) {
  iframe {
    bottom: 10% !important;
    right: -18px !important;
  }
}
.ngx-toastr {
  box-shadow: 0 0 12px 0 rgba(255, 255, 255, 0.25) !important;
  color: black !important;
  border-radius: 12px !important;
  padding: 15px !important;
}

.ngx-toastr .toast-progress {
  height: 6px;
}

.toast-info {
  background: #DDEEFE !important;
  border: 2px solid var(--ion-color-tertiary);
}

.toast-error {
  background: #FEDDE4 !important;
  border: 2px solid var(--ion-color-danger);
}

.toast-warning {
  background: #FFF1D3 !important;
  border: 2px solid var(--ion-color-warning);
}

.toast-success {
  background: #D8FFE2 !important;
  border: 2px solid var(--ion-color-success);
}

.toast-close-button span {
  color: black !important;
  font-size: 24px !important;
}

.mat-select-placeholder {
  color: white;
}

@media screen and (min-width: 721px) {
  .tablet-show {
    display: none;
  }
}
@media screen and (max-width: 720px) {
  .tablet-hide {
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .tablet-hide.mobile-show {
    display: inherit;
  }
}
@media screen and (min-width: 1281px) {
  .tablet-landscape-show {
    display: none;
  }
}
@media screen and (max-width: 1280px) {
  .tablet-landscape-hide {
    display: none;
  }
}